import { useEffect } from 'react';
import { useTypes } from './Hooks/UseTypes';
import { useModules } from './Hooks/UseModules';
import { RoutesProvider } from './Components/Routes/RoutesProvider.component';
import { useTheme } from './Hooks/UseTheme';
import { useAuth } from './Hooks/UseAuth';
import { LoadingFullScreen, Snackbar } from 'lavaa';
import { useNotifications } from './Hooks/UseNotifications';
import { useRulesets } from "./Hooks/UseRulesets";
import NoInternetConnection from './Components/Common/NoInternetConnection/NoInternetConnection.component';
import 'primereact/resources/themes/md-light-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import { useMapping } from './Hooks/UseMapping';
import { setOnReconnectCallback } from './Redux/Middleware/Connector';

function App() {

    const { fetchTypes } = useTypes();
    const { fetchModules } = useModules();
    const { loadTheme } = useTheme();
    const { loadAccount, isAuth } = useAuth();
    const { fetchNotifications } = useNotifications();
    const { fetchRulesets } = useRulesets();
    const { fetchMapping } = useMapping();

    // Init
    const init = () => {
        loadTheme();
        loadAccount();
    };

    // Load Data
    const loadData = () => {
        fetchTypes();
        fetchModules(1);
        fetchModules(3);
        fetchMapping();
        fetchRulesets();
        fetchNotifications();
    };

    useEffect(() => {
        init();
    }, []);

    // Socket Reconnect Callback
    useEffect(() => {
        setOnReconnectCallback(loadAccount);
        return () => {
            setOnReconnectCallback(null);
        };
    }, []);

    useEffect(() => {
        if(!isAuth) return;
        loadData();
    }, [isAuth]);

    if(isAuth === null) {
        return (
            <LoadingFullScreen/>
        )
    }

    // Routes Provider
    return <NoInternetConnection>
        <RoutesProvider/>
        {/* <TestLayout /> */}
        <Snackbar/>
    </NoInternetConnection>
}

export default App;
